import { useAxios } from '@/hooks/web/useAxios'

const { request } = useAxios()

export const loadAdminUsersApi = (data: any) => {
  return request({ url: '/php_api/admins/get_list', method: 'post', data })
}

export const saveAdminUserApi = (data: any) => {
  return request({ url: '/php_api/admins/edit_user', method: 'post', data })
}

export const deleteAdminUserApi = (data: any) => {
  return request({ url: '/php_api/admins/del_user', method: 'post', data })
}

export const saveAdminUserSeatNameApi = (data: any) => {
  return request({ url: '/php_api/admins/set_seat_name', method: 'post', data })
}

export const saveAdminUserPasswordApi = (data: any) => {
  return request({ url: '/php_api/admins/set_password', method: 'post', data })
}
