import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-16c833ed"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "setting-wrapper" }
const _hoisted_2 = { class: "setting-content-box" }
const _hoisted_3 = { class: "device-box-head" }
const _hoisted_4 = { class: "device-buttons-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AdminUser = _resolveComponent("AdminUser")!
  const _component_Password = _resolveComponent("Password")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("a", {
            class: _normalizeClass(["device-btn", _ctx.activeTab === 'admin_user' ? 'active-tab' : '']),
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.switchTab('admin_user')))
          }, "账号管理", 2),
          _createElementVNode("a", {
            class: _normalizeClass(["device-btn", _ctx.activeTab === 'password' ? 'active-tab' : '']),
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.switchTab('password')))
          }, "信息设置", 2)
        ])
      ]),
      (_ctx.activeTab === "admin_user")
        ? (_openBlock(), _createBlock(_component_AdminUser, { key: 0 }))
        : _createCommentVNode("", true),
      (_ctx.activeTab === "password")
        ? (_openBlock(), _createBlock(_component_Password, { key: 1 }))
        : _createCommentVNode("", true)
    ])
  ]))
}