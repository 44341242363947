
import { Options, Vue } from 'vue-class-component'

import ModifyUser from '@/components/setting/ModifyUser.vue'
import {ElMessage, ElMessageBox} from 'element-plus'

import {loadAdminUsersApi, deleteAdminUserApi, saveAdminUserApi} from '@/api/adminUser'
import {useCache} from "@/hooks/web/useCache";

@Options({
  components: { ModifyUser },
  data () {
    return {
      token: '',
      adminUsers: [],
      showModifyUser: false,
      modifyingUser: {}
    }
  },
  mounted() {
    const { wsCache } = useCache()

    this.wsCache = wsCache

    this.token = wsCache.get('token')

    this.loadAdminUsers()
  },
  methods: {
    loadAdminUsers () {
      loadAdminUsersApi({
        token: this.token
      }).then((res: any) => {
        this.adminUsers = res.list.data
        console.log(res.list.data)
      })
    },
    addUser () {
      this.showModifyUser = true
      this.modifyingUser = {}
    },
    closeModifyUser (action: string) {
      this.showModifyUser = false

      if (action === 'refresh') {
        this.loadAdminUsers()
      }
    },
    modifyUser (user: any) {
      this.showModifyUser = true
      this.modifyingUser = user
    },
    deleteUser (user: any) {
      const self = this

      ElMessageBox.confirm(
          '确定要删除该用户？',
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }
      ).then(() => {
        deleteAdminUserApi({
          token: this.token,
          id: user.id
        }).then((res: any) => {
          console.log(res)
          ElMessage.success('删除成功')

          self.loadAdminUsers()
        })
      }).catch(() => {
        console.log('Canceled')
      })
    }
  }
})
export default class AdminUser extends Vue {}
