
import { Options, Vue } from 'vue-class-component'
import {useCache} from '@/hooks/web/useCache'

import { saveAdminUserPasswordApi, saveAdminUserSeatNameApi } from '@/api/adminUser'

import { ElMessage } from 'element-plus'

@Options({
  data () {
    return {
      token: '',
      oldPassword: '',
      newPassword: '',
      seatName: ''
    }
  },
  mounted() {
    const { wsCache } = useCache()

    this.wsCache = wsCache

    this.token = wsCache.get('token')
  },
  methods: {
    doSubmit() {
      saveAdminUserPasswordApi({
        token: this.token,
        old_password: this.oldPassword,
        new_password: this.newPassword
      }).then((res: any) => {
        ElMessage.success('设置成功')
      })
    },
    doSubmit2() {
      saveAdminUserSeatNameApi({
        token: this.token,
        seat_name: this.seatName
      }).then((res: any) => {
        ElMessage.success('保存成功')
      })
    }
  }
})
export default class Password extends Vue {}
