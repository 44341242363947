
import { Options, Vue } from 'vue-class-component'

import { saveAdminUserApi } from '@/api/adminUser'
import {useCache} from "@/hooks/web/useCache";

@Options({
  props: {
    user: {
      type: Object
    }
  },
  data: () => {
    return {
      token: '',
      userInfo: {},
      userRules: {
        username: [{
          required: true,
          message: '请输入用户名',
          trigger: 'blur'
        }],
        name: [{
          required: true,
          message: '请输入姓名',
          trigger: 'blur'
        }],
        password: [{
          required: true,
          message: '请输入密码',
          trigger: 'blur'
        }],
        mobile: [{
          required: true,
          message: '请输入手机号',
          trigger: 'blur'
        }],
        gender: [{
          required: true,
          message: '请选择性别',
          trigger: 'change'
        }],
        is_police: [{
          required: true,
          message: '请选择是否接警',
          trigger: 'change'
        }]
      }
    }
  },
  mounted() {
    const { wsCache } = useCache()

    this.wsCache = wsCache

    this.token = wsCache.get('token')

    this.userInfo = JSON.parse(JSON.stringify(this.user))
  },
  methods: {
    cancelSubmit: function () {
      this.$emit('closeModifyUser')
    },
    doSubmit: function () {
      const self = this

      this.$refs.submitForm.validate((valid: boolean) => {
        if (valid) {
          self.userInfo.token = self.token

          saveAdminUserApi(self.userInfo).then((res: any) => {
            this.$emit('closeModifyUser', 'refresh')
          })
        }
      })
    }
  }
})
export default class ModifyUser extends Vue {}
