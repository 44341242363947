
import { Options, Vue } from 'vue-class-component'
import AdminUser from '@/components/setting/AdminUser.vue'
import Password from '@/components/setting/Password.vue'

@Options({
  components: {AdminUser, Password},
  data () {
    return {
      activeTab: 'admin_user'
    }
  },
  methods: {
    switchTab (tab: string) {
      this.activeTab = tab
    }
  }
})
export default class Setting extends Vue {}
